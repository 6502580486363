/*.react-datepicker__year-dropdown {*/
/*    background-color: #2F2F2F;*/
/*    box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);*/
/*    color: white !important;*/
/*}*/

.react-datepicker {
    overflow: hidden;
    border-radius: 5px !important;
    border: 0.5px solid rgba(127, 127, 127, 0.4) !important;
}

.react-datepicker__today-button {
    border: none !important;

}

.react-datepicker__today-button {
    background-color: #F6F6F6 !important;
}

/*.react-datepicker__today-button:hover {*/
/*    color: cyan !important;*/
/*}*/

.react-datepicker__header {
    padding-top: 20px !important;
    background-color: #F6F6F6 !important;
    border: none !important;
}

/*.react-datepicker__day {*/
/*    color: white !important;*/
/*}*/

/*.react-datepicker__header {*/
/*    padding-top: 0.8em;*/
/*}*/

.react-datepicker__day--in-range {
    background-color: #1E5FFD !important;
    color: black;
    font-weight: bold;
}

.react-datepicker__day--selected {
    background-color: #1E5FFD !important;
    color: black;
    font-weight: bold;

}

.react-datepicker__day--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #1E5FFD !important;
    color: #fff;
}

/*.react-datepicker__month {*/
/*    margin: 0.4em 1em;*/
/*}*/

.react-datepicker__day-name, .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
}

.react-datepicker__current-month {
    font-size: 1em;
    margin: 10px 0 10px 0;
}

.react-datepicker__navigation {
    top: 2rem;
    line-height: 1.7em;
    border: 0.45em solid transparent;
}

.react-datepicker__input-container input {
    background-color: #F6F6F6 !important;
    border: 0.5px solid rgba(127, 127, 127, 0.4) !important;
    border-radius: 5px !important;
    font-size: 16px !important;
    line-height: 19px !important;
    text-align: left !important;
    color: #5e5e5e !important;
    font-weight: 300 !important;
    height: 48px !important;
    padding-left: 20px !important;
    width: 100% !important;
}



:root {
    --available-text-color: #02A735;
    --available-status-color: #BBD400;
    --available-background-color: #F7FDF2;
    --busy-color: #FDA050;
    --archived-color: #989898;
    --default-blue: #0560FC;
    --default-red: #bb0505;
    --navicon-color: #7F7F7F;
}

@import "https://use.fontawesome.com/releases/v5.13.1/css/all.css";

/*  */
@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    src: local('Proxima Nova'), url('./assets/font/Mark_Simonson_Proxima_Nova_Regular_Italic.woff') format('woff');
}

@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    src: local('Proxima Nova'), url('./assets/font/Mark_Simonson_Proxima_Nova_Regular.woff') format('woff');
}

@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 250;
    src: local('Proxima Nova'), url('./assets/font/Mark_Simonson_Proxima_Nova_Thin_Italic.woff') format('woff');
}

@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 250;
    src: local('Proxima Nova'), url('./assets/font/Mark_Simonson_Proxima_Nova_Thin.woff') format('woff');
}

@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 300;
    src: local('Proxima Nova'), url('./assets/font/Mark_Simonson_Proxima_Nova_Light.woff') format('woff');
}

@font-face {
    font-family: 'Proxima Nova';
    font-style: italic;
    font-weight: 300;
    src: local('Proxima Nova'), url('./assets/font/Mark_Simonson_Proxima_Nova_Light_Italic.woff') format('woff');
}

@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    src: local('Proxima Nova'), url('./assets/font/MarkSimonsonProximaNovaSemiboldItalic.woff');
}

@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    src: local('Proxima Nova'), url('./assets/font/Mark_Simonson_Proxima_Nova_Semibold.woff') format('woff');
}

@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    src: local('Proxima Nova'), url('./assets/font/Mark_Simonson_Proxima_Nova_Bold_Italic.woff') format('woff');
}

@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    src: local('Proxima Nova'), url('./assets/font/Mark_Simonson_Proxima_Nova_Bold.woff') format('woff');
}

@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 800;
    src: local('Proxima Nova'), url('./assets/font/Mark_Simonson_Proxima_Nova_Extrabold_Italic.woff') format('woff');
}

@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 800;
    src: local('Proxima Nova'), url('./assets/font/Mark_Simonson_Proxima_Nova_Extrabold.woff') format('woff');
}

@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 900;
    src: local('Proxima Nova'), url('./assets/font/Mark_Simonson_Proxima_Nova_Black_Italic.woff') format('woff');
}

@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 900;
    src: local('Proxima Nova'), url('./assets/font/Mark_Simonson_Proxima_Nova_Black.woff') format('woff');
}

@font-face {
    font-family: 'FontAwesome';
    font-style: normal;
    font-weight: 900;
    src: local('Proxima Nova'), url('https://use.fontawesome.com/releases/v5.13.1/css/all.css') format('woff');
}

*,
body {
    font-family: 'Proxima Nova', sans-serif;
}

main {
    height: 100%;
}

p {
    word-break: break-word;
}

a {
    cursor: pointer;
}

.bold {
    font-weight: 600 !important;
}

.bolder {
    font-weight: 700 !important;
}

.light {
    font-weight: 250 !important;
}

.greybg {
    background-color: #FAFAFA;
}

.blueBg {
    background-color: #0560FC;
}

.logoOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #FAFAFA;
    z-index: 20;
}

.logoAnimation {
    animation: logoAnima 2s infinite;
}

@keyframes logoAnima {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.borderBtm {
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
    margin: 0 32px;
}

.headerbg {
    background-color: #22245E;
    max-height: 73px;
    min-height: 73px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 32px;
    padding-right: 32px;
}

.blogo {
    height: 2rem;
}

.alignMiddle {
    display: flex;
    align-items: center;
}

.whiteText {
    color: white;
}

.container1 {
    width: 100%;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
}

.defaultBLue {
    color: #0560FC;
    text-decoration: none;
}

.defaultGreyText {
    color: #7F7F7F;
}

.darkGrey {
    color: #3A3E44;
}

.bold {
    font-weight: 900;
}

.manager {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 19px;
    opacity: 0.7439778645833334;
    text-align: left;
    margin: 0px;
}

.user {
    color: #FFFFFF;
    font-size: 19px;
    text-align: left;
}

.ovalProf {
    color: #FFFFFF;
    font-size: 30px !important;
    line-height: 56px;
    background-size: cover;
    border: 10px solid #F2F7FE;
    border-radius: 100%;
    width: 90px;
    height: 90px;
    min-width: 90px;
    background-color: #0560FC;
}

.archief {
    color: #FFFFFF;
    font-size: 16.8px;
    line-height: 28px;
    width: 42px;
    text-align: center;
}

.navigationLink {
    border: 1px solid transparent;
    margin-bottom: 0.8rem;
    padding: 0.8rem 1rem;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    color: #7F7F7F;
    fill: #7F7F7F;
}

.navigationLink > svg {
    height: 1.25rem;
    width: 1.25rem;
}

.activeLink, .navigationLink:hover {
    border: 1px solid #055FFC;
    background-color: #F2F7FE;
    border-radius: 3px;
    color: #055FFC !important;
    fill: #055FFC;
}

.navText {
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    margin-left: 1.438rem;
}

.navigationIcon {
    /* height: 1.25rem; */
    /* width: 1.25rem; */
}

.line {
    border-left: 1px solid #E2E2E2;
    height: 100%;
}

.horizontalLine {
    border: 1px solid #E2E2E2;
}

.mainContent {
    height: 100%;
    padding: 32px !important;
}

.content {
    padding: 32px;
}

.backBtn {
    fill: #0560FC;
    height: 13px;
}

.ovalImg {
    background-size: cover;
    border: 10px solid #F2F7FE;
    border-radius: 100%;
    width: 90px;
    height: 90px;
    min-width: 90px;
}

.ovalImg.blue {
    background-color: #0560FC;
}

.profileName {
    color: #383C41;
    font-size: 30px;
    line-height: 36px;
    text-align: left;
    font-weight: 600;
}

.profileNumber {
    color: #7F7F7F;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
}

.availableBtn {
    color: #02A735 !important;
    background-color: #F7FDF2;
    border-radius: 3.5px;
    width: 119px;
    height: 34px;
    border-width: 0;
    display: block;
    text-align: center;
    min-width: 119px;
    padding: 0.4rem 0rem;
    text-decoration: none;
}

.noPointerEvents {
    pointer-events: none;
    cursor: none;
}

.activeBtn {
    background-color: #F2F7FE !important;
    color: #3C7BFC;
    border-radius: 3.5px;
    width: 119px;
    border-width: 0;
    display: block;
    text-align: center;
    min-width: 119px;
    height: 34px;
    padding: 0.4rem 0rem;
    text-decoration: none;
    pointer-events: none;
    cursor: none;
}

.availableIcon {
    fill: #02A735;
    height: 13px;
}

.saveBtn {
    color: white;
    background-color: #0560FC;
    border-radius: 3.5px;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0);
    display: block;
    text-align: center;
    min-width: 90px !important;
    max-width: 90px !important;
    height: 34px;
    padding: 0.4rem 0rem;
    text-decoration: none;
}

.saveBtn:hover {
    background-color: white;
    color: #0560FC;
    border: 1px solid #0560FC;
    transition: all 0.2s 0s ease-in-out;
}

.pdfBtn {
    color: white;
    background-color: #0560FC;
    border-radius: 3.5px;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0);
    display: block;
    text-align: center;
    min-width: 90px;
    height: 34px;
    padding: 0.4rem 0rem;
    text-decoration: none;
}

.pdfBtn:hover {
    background-color: white;
    color: #0560FC;
    border: 1px solid #0560FC;
    transition: all 0.2s 0s ease-in-out;
}

.cancelBtn {
    display: inline-block;
    color: #0560FC;
    font-size: 14px;
    line-height: 17px;
    border: 1px solid #0560FC;
    border-radius: 3.5px;
    background-color: transparent;
    text-decoration: none;
    text-align: center;
    min-width: 90px;
    height: 34px;
    padding: 0.4rem 0rem;
    vertical-align: middle;
}

.cancelBtn:hover {
    background-color: #0560FC;
    border: 1px solid rgba(255, 255, 255, 0);
    transition: all 0.2s 0s ease-in-out;
    color: white;
}

.docBtn {
    color: #0560FC;
    font-size: 14px;
    line-height: 17px;
    border: 1px solid #0560FC;
    border-radius: 3.5px;
    background-color: transparent;
    margin-left: 15px;
    display: block;
    text-align: center;
    min-width: 90px;
    height: 34px;
    padding: 0.4rem 0rem;
    text-decoration: none;
}

.disabledBlue {
    color: #0560fc2f !important;
    background-color: #0560fc20 !important;
    cursor: none !important;
    border: 1px solid #0560fc2f !important;
    pointer-events: none !important;
}

.docBtn:hover {
    background-color: #0560FC;
    border: 1px solid rgba(255, 255, 255, 0);
    transition: all 0.2s 0s ease-in-out;
    color: white;
}

.removeBtn {
    color: #bb0505;
    font-size: 14px;
    line-height: 17px;
    border: 1px solid #bb0505;
    border-radius: 3.5px;
    height: 34px;
    padding: 0.41rem 0rem;
    text-align: center;
    background-color: transparent;
    text-decoration: none;
    min-width: 90px;
    display: inline-block;
}

.removeBtn:hover {
    background-color: #bb0505;
    border: 1px solid rgba(255, 255, 255, 0);
    transition: all 0.2s 0s ease-in-out;
    color: white;
}

.disabledRed,
.removeBtn:disabled,
.removeBtn[disabled] {
    color: #bb050559 !important;
    background-color: #bb05052d !important;
    cursor: none !important;
    border: 1px solid #bb050531 !important;
    pointer-events: none !important;
}

.editBtn {
    color: #0560FC;
    font-size: 14px;
    line-height: 17px;
    border: 1px solid #0560FC;
    border-radius: 3.5px;
    display: block;
    text-align: center;
    min-width: 90px;
    height: 34px;
    padding: 0.45rem 0rem;
    background-color: transparent;
    text-decoration: none;
}

.editBtn:hover {
    background-color: #0560FC;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0);
    transition: all 0.2s 0s ease-in-out;
}

.editBtnFull {
    color: white;
    font-size: 14px;
    line-height: 17px;
    background-color: #0560FC;
    border-radius: 3.5px;
    display: block;
    text-align: center;
    min-width: 90px;
    height: 34px;
    padding: 0.5rem 0rem;
    text-decoration: none;
    border: 1px solid rgba(255, 255, 255, 0);
}

.editBtnFull:hover {
    background-color: white;
    color: #0560FC;
    border: 1px solid #0560FC;
    transition: all 0.2s 0s ease-in-out;
}

.roleContainer {
    background-color: #FAFAFA;
}

.radioBtn {
    color: #7F7F7F;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
}

.form-check-input {
    margin-right: 10px !important;
}

/* companies  style */
.companyImgOval {
    border: 4px solid #F2F7FE;
    border-radius: 100%;
    width: 36px;
    height: 36px;
    min-width: 36px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    margin-top: 0px;
}

.peopleFullname {
    color: #383C41;
    font-size: 20px;
    line-height: 24px;
}

.disconnectText.cancel {
    color: var(--default-blue);
}

.disconnectText.disconnect {
    color: var(--default-red);
}

.disconnectText {
    font-size: 14px;
    line-height: 17px;
    padding-right: 6.2rem;
    font-weight: 600;
}

.checkbox {
    background-color: #FFFFFF;
    border: 0.7px solid #E2E2E2;
    border-radius: 2.1px;
    width: 26px;
    height: 26px;
}

.checkboxLabel {
    font-size: 20px;
    font-weight: 600;
    color: #383C41;
    line-height: 24px;
}

.checkBoxContainer {
    padding-left: 1.6rem !important;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
}

.personalDet {
    z-index: 20 !important;
    width: 210px;
}

.personalDetails {
    background-color: #FFFFFF;
    border: 2px solid #F6F6F6;
    border-radius: 8px;
    box-shadow: 0 1px 1px 0 rgba(229, 229, 229, 0.5);
    width: 212px;
    height: 271px;
    text-decoration: none;
    padding: 40px;
    min-width: 212px;
    max-width: 212px;
}

.personalDetailsDiv1 {
    transform: rotate(9deg);
    position: absolute;
    z-index: -2 !important;
}

.personalDetailsDiv2 {
    transform: rotate(7deg);
    position: absolute;
    z-index: -1 !important;
}

.professionDiv {
    border: 2px solid #F6F6F6;
    border-radius: 8px;
    box-shadow: 0 1px 1px 0 rgba(229, 229, 229, 0.5);
    width: 212px;
    height: 271px;
    text-decoration: none;
    padding: 40px;
}

.professionDiv:hover {
    background-color: rgba(246, 246, 246, 0.308);
    transition: all 0.2s 0s ease-in-out;
    border: 2px solid #a9a9a933;
}

.infoAlert {
    background-color: #F2F7FE;
    border-radius: 3.5px;
    max-width: 295px;
    color: #0560FC;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    margin-top: 60px;
    margin-left: 20px;
}

.alertMargin {
    margin-top: 82px !important;
}

.xmark {
    fill: #B1B5B9;
    height: 0.7rem;
}

.circleInfo {
    fill: #0560FC;
    height: 20px;
    width: 20px;
}

.addNewFunctionBox {
    background-color: #F6F6F6;
    border-radius: 8px;
    width: 210px;
    height: 271px;
    border: 2px solid rgba(255, 255, 255, 0);
}

.addNewFunctionBox:hover {
    border: 2px solid #a9a9a933;
    background-color: rgba(246, 246, 246, 0.692);
    transition: all 0.2s 0s ease-in-out;
}

.addNewFunctioLink {
    text-decoration: none;
}

.plusICon {
    height: 25px;
    width: 25px;
    fill: #055FFC;
}

/* my resume edit */
.dragAndDropPicture {
    background-color: #FDFDFD;
    border: 0.5px solid rgba(127, 127, 127, 0.12);
    border-radius: 5px;
    padding: 20px 20px;
    max-width: 720px;
    width: 100%;
    min-height: 244px;
    height: 100%;
    max-height: 343px;
}

.uploadPicture {
    background-size: 100%;
    border: 20px solid #F2F7FE;
    border-radius: 100%;
    background-color: #055FFC;
    max-height: 180px;
    width: 100%;
    max-width: 180px;
    height: 100%;
    min-height: 180px;
    min-width: 180px;
}

.cammeraICon {
    width: 56px;
    height: 48px;
    fill: #ffffff;
}

.pencilIcon {
    width: 15px;
    height: 15px;
    fill: #055FFC;
}

.formGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
}

.radioBtnsGrid {
    display: grid;
    grid-template-columns: 1fr 2fr 3fr 2.5fr 3fr;
    grid-gap: 10px;
    max-width: 100%;
}

.skillGrid {
    display: grid;
    grid-template-columns: 6fr 1fr;
    grid-gap: 20px;
}

.editInput {
    background-color: #F6F6F6;
    border: 0.5px solid rgba(127, 127, 127, 0.4);
    border-radius: 5px;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    color: #5e5e5e;
    font-weight: 300;
    height: 48px;
    padding-left: 20px;
}

.editLabel {
    font-weight: 600;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #AAAAAA;
    opacity: 1;

    /* Firefox */
    font-weight: 400;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #AAAAAA;
    font-weight: 400;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #AAAAAA;
    font-weight: 400;
}

.editTextArea {
    background-color: #F6F6F6;
    border: 0.5px solid rgba(127, 127, 127, 0.4);
    border-radius: 5px;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    color: #AAAAAA;
    min-height: 148px;
    width: 100%;
    padding: 10px;
}

.navigation {
    padding-top: 63px;
    padding-left: 32px !important;
    padding-right: 32px !important;
}

.navbar-toggler:focus {
    outline: none !important;
    box-shadow: none !important;
}

/* candidates */
.custom-input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.addCandidateBtn {
    color: #0560FC;
    border-radius: 3.5px;
    background-color: #F2F7FE;
    font-weight: 500;
    border: 1px solid rgba(5, 96, 252, 0);
    display: block;
    text-align: center;
    min-width: 210px;
    max-width: 210px;
    height: 37px;
    padding: 0.45rem 0rem;
    text-decoration: none;
}

.addCandidateBtn:hover {
    border: 1px solid rgba(5, 96, 252, 0.5);
    transition: all 0.2s 0s ease-in-out;
}

.removeEducatioBtn {
    color: #FF0000;
    border-radius: 3.5px;
    background-color: rgba(255, 0, 0, 0.11);
    font-weight: 500;
    border: 1px solid rgba(255, 0, 0, 0);
    display: block;
    text-align: center;
    min-width: 210px;
    max-width: 210px;
    height: 37px;
    padding: 0.45rem 0rem;
    text-decoration: none;
}

.removeEducatioBtn:hover {
    border: 1px solid rgba(255, 0, 0, 0.5);
    transition: all 0.2s 0s ease-in-out;
    color: #FF0000;
}

.candidatesNumber {
    color: #383C41;
    font-size: 20px;
    line-height: 24px;
    width: 40px;
    text-align: left;
}

/* text on the left corner in candidates details */
.candidatesDetailProfession {
    color: #7F7F7F;
    font-size: 24px;
    line-height: 29px;
}

.borderRight {
    padding-right: 2rem;
    margin-right: 2rem;
    border-right: 1px solid #E2E2E2;
}

.candidatesSearched {
    border: 0.5px solid #E6E6E6;
    border-radius: 5px;
    box-shadow: 0 1px 1px 0 rgba(229, 229, 229, 0.5);
    width: 100%;
    height: 37px;
    max-width: 454px;
    min-width: 454px;
    display: block;
    padding: 0px 0px 0px 40px;
    background: #FAFAFA url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23AAAAAA' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
}

/*  */
.userSearchBox {
    background-color: #FAFAFA;
    padding: 32px 32px 10px 32px;
}

.availableStatatusCircle {
    background-color: #02A735;
    border-radius: 100%;
    width: 15px;
    height: 15px;
}

.activeStatusCircle {
    border-radius: 100%;
    width: 15px;
    height: 15px;
}

.activeStatusCircle.archived {
    background-color: var(--archived-color);
}

.activeStatusCircle.available {
    background-color: var(--default-blue);
}

.activeStatusCircle.busyStatus {
    background-color: var(--busy-color);
}

.activeBtn.archived {
    background-color: var(--archived-color) !important;
    color: white !important;
}

.activeBtn.available {
    background-color: #F2F7FE;
}

.activeBtn.busyStatus {
    color: white !important;
    background-color: var(--busy-color) !important;
}


.usersSearched {
    border: 0px solid #E6E6E6;
    border-radius: 5px;

    /* box-shadow: 0 1px 1px 0 rgba(229, 229, 229, 0.5); */
    width: 100%;
    height: 37px;
    max-width: 382px;
    display: block;
    padding: 0px 0px 0px 40px;
    background: white url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
}

.usersGrid {
    padding: 0 32px;
    display: grid;
    grid-template-columns: 5fr 2fr 2fr 6fr;
}

.usersGridItem {
    padding: 30px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.usersGridLabel {
    padding: 15px 0px 15px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.candidatesCard {
    background-color: #FFFFFF;
    border: 2px solid #F6F6F6;
    border-radius: 8px;
    box-shadow: 0 1px 1px 0 rgba(229, 229, 229, 0.5);
    width: 212px;
    height: 271px;
    margin-left: auto;
    margin-right: auto;
    max-width: 250px;
}

.statusLight {
    background-color: #BBD400;
    border-radius: 100%;
    width: 7px;
    height: 7px;
}

.candidateNUmber {
    color: #7F7F7F;
    font-size: 18 Fpx;
    line-height: 14px;
}

.overview,
.candidatesName {
    color: #383C41;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
}

.detailsBtn {
    color: #FFFFFF;
    font-size: 14px;
    background-color: #0560FC;
    border-radius: 3.5px;
    padding: 0.4rem 3rem;
    text-decoration: none;
    border: 1px solid rgba(255, 255, 255, 0);
}

.detailsBtn:hover {
    color: #0560FC;
    background-color: white;
    border: 1px solid #0560FC;
    transition: all 0.2s 0s ease-in-out;
}

.templateSearch {
    border: 0.5px solid #E6E6E6;
    border-radius: 5px;
    box-shadow: 0 1px 1px 0 rgba(229, 229, 229, 0.5);
    width: 100%;
    height: 37px;
    color: #7F7F7F;
    font-size: 16px;
    max-width: 342px;
    min-width: 295px;
    display: block;
    padding: 0px 0px 0px 40px;
    background: #FAFAFA url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
}

.templateBox {
    background-color: #FFFFFF;
    border: 2px solid #F6F6F6;
    border-radius: 8px;
    box-shadow: 0 1px 1px 0 rgba(229, 229, 229, 0.5);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 75px;
    row-gap: 1rem;
    column-gap: 1rem;
}

.temlateIconBox {
    background-color: #FFFFFF;
    border: 1px solid #E2E2E2;
    border-radius: 3px;
    width: 43px;
    height: 37px;
}

.eyeIcon {
    height: 16px;
    width: 20px;
    color: #7F7F7F;
}

/* template details information grid */
.detailsGrid {
    display: grid;
    grid-template-columns: 1fr 2fr 4fr;
    max-width: 350px;
    padding-bottom: 40px;
}

.mainContentGrid {
    display: grid;
    grid-template-columns: 240px auto;
}

/* login screen css */
.loginContainer {
    background-color: #FFFFFF;
    border: 1px solid rgba(151, 151, 151, 0.49);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 2rem 4.5rem;
}

.loginHeading {
    color: #383C41;
    font-size: 36px;
    text-align: center;
    font-weight: 600;
}

.loginMain {
    background-color: #FAFAFA;
    height: 92vh;
}

.subtitle {
    color: #626468;
    font-size: 22px;
    line-height: 36px;
    text-align: center;
    margin-bottom: 20px;
}

.loginBtn {
    color: white;
    background-color: #0560FC;
    border-radius: 3.5px;
    font-size: 14px;
    line-height: 17px;
    padding: 0.55rem 2.1rem;
    text-decoration: none;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0);
    margin-bottom: 20px;
    margin-top: 30px;
}

.loginBtn:hover {
    background-color: white;
    color: #0560FC;
    border: 1px solid #0560FC;
    transition: all 0.2s 0s ease-in-out;
}

.loginLabel {
    font-weight: 400;
}

.loginInput {
    background-color: #F6F6F6;
    border: 0.5px solid rgba(127, 127, 127, 0.4);
    border-radius: 5px;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    color: #5e5e5e;
    font-weight: 300;
    width: 300px;
    height: 48px;
    padding-left: 20px;
}

.logOutBtn {
    color: #bb0505;
    text-decoration: none;
    padding: 0.69rem 1rem 0.69rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #30212100;
    border-radius: 3.5px;
}

.logOutBtn:hover {
    border: 1px solid #bb0505;
    background-color: rgba(187, 5, 5, 0.03);
    color: #bb0505;
    border-radius: 3.5px;
}

.logoutText {
    margin-left: 1.65rem;
}

.forgotPassword {
    color: #0560FC;
    text-decoration: none;
}

.forgotPassword:hover {
    color: rgba(5, 96, 252, 0.51);
    transition: all 0.2s 0s ease-in-out;
    text-decoration: underline rgba(5, 96, 252, 0.51) 1px;
}

/* access screen */
.accessGrid {
    padding: 0 32px;
    display: grid;
    grid-template-columns: 1fr 3fr 2fr 3fr 3fr 4fr;
}

.accessGridItem {
    padding: 30px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.accessGridLabel {
    padding: 15px 0px 15px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

/* modal */
.customModalContainer {
    position: fixed;
    z-index: 99;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
}

.customModal {
    background-color: #F2F7FE;
    border-radius: 3.5px;
    max-width: 100%;
    color: #0560FC;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    position: fixed;
}

.magicModal > div > h5 {
    text-align: left !important;
}

.magicModal {
    overflow: hidden;
    background-color: white;
    border-radius: 3.5px;
    color: #0560FC;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    position: fixed;
    min-width: 20%;
    max-width: 90%;
}

.cv-modal {
    background-color: white;
    border-radius: 3.5px;
    width: 100%;
    max-width: 300px;
    color: #0560FC;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    position: fixed;
}

.paginationBox {
    display: inline-block;
    color: #0560FC;
    margin: 20px 0px;
}

.paginationBox a {
    padding: 5px 12px;
    margin: 0 5px;
    text-decoration: none;
}

.number.active {
    font-weight: 600;
    background-color: rgba(5, 96, 252, 0.49);
    border-radius: 100%;
    color: white;
}

.number:hover {
    background-color: rgba(5, 96, 252, 0.84);
    border-radius: 100%;
    color: white;
    transition: all 0.2s 0s ease-in-out;
}

.paginationArrow {
    padding: 7px;
}

.paginationArrow:hover {
    background-color: rgba(5, 96, 252, 0.84);
    border-radius: 100%;
    color: white;
    padding: 7px;
    transition: all 0.2s 0s ease-in-out;
}

.mainContentGrid > div {
    min-height: 95vh;
}

.availableBtn.dropdown-toggle::after {
    display: none;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.busy {
    color: white !important;
    background-color: #FDA050;
    border-radius: 3.5px;
    width: 119px;
    height: 34px;
    border-width: 0;
    display: block;
    text-align: center;
    min-width: 119px;
    padding: 0.4rem 0rem;
    text-decoration: none;
}

.customDropdwn ul.dropdown-menu {
    padding: 0.1rem 0.1rem;
    min-width: 70%;
    border: 1px solid rgba(0, 0, 0, .15);
}

.tableLoader {
    padding: 0px 32px;
}

.rotateIcon {
    transform: rotate(150deg);
}

.saveBtn > .spinner-border {
    width: 20px !important;
    height: 20px !important;
}

.saveBtn:hover > .spinner-border {
    color: #0560FC !important;
}

#snackbar {
    background-color: #F2F7FE;
    border-radius: 3.5px;
    max-width: 300px;
    color: #0560FC;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    position: fixed;
    margin-left: -150px;
    z-index: 1;
    left: 50%;
    bottom: 30px;
}

#snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }

    to {
        bottom: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }

    to {
        bottom: 0;
        opacity: 0;
    }
}

.closeIcon {
    position: absolute;
    right: 10px;
    top: 6px;
}

@media (max-width: 428px) {
    .addCandidateBtn {
        margin-top: 10px;
    }

    .addCompanyBtnBox {
        width: 100%;
    }
}


@media (max-width: 575px) {
    .mobilePadding {
        padding: 10px 32px 32px 32px !important;
    }

    .mainContentGrid > div {
        min-height: 100%;
    }

    .navigation {
        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: #f9f9f980;
        border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    }

    .infoAlert {
        margin: 20px auto 0;
    }

    .line {
        border-left: none;
    }

    .professionDiv {
        min-width: 250px;
        height: 297px;
        width: 250px;
    }

    .addNewFunctionBox {
        width: 100%;
        height: 297px;
    }

    .addNewFunctioLink {
        min-width: 250px;
        width: 250px;
    }

    .formGrid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 0px;
    }

    .formGridItem {
        margin-bottom: 20px;
    }

    .radioBtnsGrid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }

    .mainContentGrid {
        display: grid;
        grid-template-columns: auto;
    }

    .content {
        padding: 0px;
        padding-top: 20px;
    }

    .disconnectText {
        padding-right: 0px;
    }

    .candidatesCard {
        width: 100%;
    }

    .borderRight {
        border: none;
    }

    .fullWithMobile {
        width: 100%;
    }

    .usersSearched,
    .templateSearch,
    .candidatesSearched {
        width: 100%;
        min-width: 100px;
    }

    .docBtn {
        margin-left: 0;
    }

    .custom-input {
        width: 100%;
    }

    .usersGrid {
        grid-template-columns: auto auto;
        padding: 0px 32px;
    }

    .accessGrid {
        padding: 0 32px;
        display: grid;
        grid-template-columns: 1fr 2fr 2fr  1fr;
    }

    .checkBoxContainer {
        padding-left: 0rem !important;
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .navigation {
        padding-top: 2.5rem;
        margin-top: 0rem;
        background-color: #f9f9f980;
        border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    }

    .mainContentGrid > div {
        min-height: 100%;
    }

    .line {
        border-left: none;
    }

    .mainContentGrid {
        display: grid;
        grid-template-columns: auto;
    }

    .usersSearched,
    .templateSearch,
    .candidatesSearched {
        min-width: 300px;
    }

    .usersGrid {
        grid-template-columns: 3fr 2fr 2fr 2fr;
    }

    .radioBtnsGrid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
        max-width: 800px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .navigation {
        padding-top: 2rem;
        background-color: #f9f9f980;
        border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    }

    .mainContentGrid > div {
        min-height: 100%;
    }

    .line {
        border-left: none;
    }

    .mainContentGrid {
        display: grid;
        grid-template-columns: auto;
    }

    .usersSearched,
    .templateSearch,
    .candidatesSearched {
        min-width: 300px;
    }

    .usersGrid {
        grid-template-columns: 3fr 2fr 2fr 4fr;
    }
}

.navbar {
    display: block !important;
}

@media (max-width: 575px) {
    .loginContainer {
        padding: 2rem 0.8rem;
        width: 98%;
    }
}

.error-text {
    color: red;
    font-size: 12px;
    margin-top: 10px;
}

.error-text.input-text {
    position: absolute;
    bottom: -25px;
    left: 0;
}

.removeBtn {
    color: red;
    border: 1px solid red;
}

.mt10 {
    margin-top: 10px;
}

.resume-cv-image {
    width: 11.25rem;
    border-radius: 100%;
    height: 11.25rem;
    flex-shrink: 0;
    object-fit: cover;
}

.logOutBtn.v2 {
    background: none;
    width: 100%;
}

.defaultBLue span {
    display: block;
}

.backBtn {
    width: 1.5625rem;
    height: 1.5625rem;
}

.function-wrapper {
    max-width: 45rem;
}

.hidden {
    display: none !important;
}

.cursor-pointer {
    cursor: pointer;
}

.cancelBtn.removeBtn.function {
    margin-right: 15px;
}

.spinner-border.spinner-border-sm {
    margin-right: 5px;
}

.cancelBtn.companyCancelBtn {
    margin-right: 20px;
}

.editTextArea.function {
    padding: 10px;
}

.editTextArea {
    color: #575757;
    font-weight: 500;
}

.editInput {
    color: #575757;
    font-weight: 500;
}

.th-loading {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}

.icon-helper {
    content: '';
    width: 20px;
    height: 20px;
    background: no-repeat;
    z-index: 20;
    background-size: 100%;
    cursor: pointer;
}

.icon-helper.sidebar {
    opacity: 1;
}

.icon-helper.trophy {
    background-image: url('./assets/icons/trophy.svg');
}

.navigationLink.activeLink .trophy, .navigationLink.activeLink .icon-helper.palette {
    background-image: url('./assets/icons/troph-hover.svg')
}

.icon-helper.palette {
    background-image: url('./assets/icons/palette.svg');
    transform: rotate(240deg);
}

.navigationLink:hover .palette, .navigationLink.activeLink .icon-helper.palette {
    background-image: url('./assets/icons/palette-hover.svg')
}


.icon-helper.person {
    background-image: url('./assets/icons/person.svg');
}

.navigationLink:hover .person, .navigationLink.activeLink .icon-helper.person {
    background-image: url('./assets/icons/person-hover.svg');
}

.icon-helper.hierarchy {
    background-image: url('./assets/icons/hierarchy.svg');
}

.navigationLink:hover .hierarchy, .navigationLink.activeLink .icon-helper.hierarchy {
    background-image: url('./assets/icons/hierarchy-hover.svg');
}

/*ICONS END*/

.initials-holder {
    text-align: center;
    color: #FFFFFF;
    background-color: #0560FC;
    line-height: inherit;
}

.initials-holder.candidatesInitialsHolder.companyImgOval {
    font-size: 13px !important;
    line-height: 31px !important;
}

.initials-holder.companyImgOvalInitials.companyImgOval {
    font-size: 15px !important;
    padding-top: 3px;
}

.initials-holder.accessImgOvalInitials.companyImgOval {
    font-size: 15px !important;
    padding-top: 3px;
}


.align-items-center.img-holder-wrapper {
    margin: 0px 20px;
}


.candidate-status {
    border-radius: 100%;
    width: 10px !important;
    height: 10px !important;
}

.candidate-status.available {
    background-color: var(--available-status-color);
    color: var(--available-text-color) !important;
}

.candidate-status.busyStatus {
    background-color: var(--busy-color);
}

.candidate-status.archived {
    background-color: var(--archived-color);
}

.availableBtn.available {
    background-color: var(--available-background-color);
    color: var(--available-text-color) !important;
}

.availableBtn.busyStatus {
    background-color: var(--busy-color);
    color: white !important;
}

.availableBtn.archived {
    background-color: var(--archived-color);
    color: white !important;
}


.saveBtn > .spinner-border {
    width: 13px !important;
    height: 13px !important;
}


.line-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.line-clamp-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.line-clamp-4 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}

.candidate-box-name {
    max-width: 80%;
}

.candidatesDetailProfession {
    max-width: 20rem;
    overflow: hidden;
}

.profileName.candidatesProfileName {
    padding-right: 10px;
}


.dropdown-menu > .archived {
    color: white !important;
    background-color: var(--archived-color);
    border-radius: 3.5px;
    width: 119px;
    height: 34px;
    border-width: 0;
    display: block;
    text-align: center;
    min-width: 119px;
    padding: 0.4rem 0rem;
    text-decoration: none;
}

.temlateIconBox .candidate-eye {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.templateBox .magic-link {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.cancelBtn.archive-action {
    display: inline-block;
    color: white;
    border: 1px solid #a9a9a933;
    background-color: var(--archived-color);
}

.cancelBtn.archive-action:hover {
    background-color: #0560FC;
    border: 1px solid rgba(255, 255, 255, 0);
    transition: all 0.2s 0s ease-in-out;
    color: white;
}


.manager {
    width: 100% !important;
}

.navbar-dark {
    border: none !important;
}

.navbar-toggler-icon {
    width: 1.84rem !important;
}


.sticky-header {
    position: sticky;
    top: 0;
    z-index: 51;
}


.checkBoxContainer {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}

.custom-input {
    min-width: 260px
}


/*  class tu add to the input when his not valid */

.inputErrors {
    border: 1px solid #FF0000;
}


.no-href-style {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
}

label.required:after {
    content: '*';
    color: red;
}

.availability-select {
    border-right: 10px solid transparent;
}

button[disabled]:not(.loading-active) {
    background-color: var(--navicon-color);
    color: white;
    cursor: initial;
    border: initial;
    pointer-events: none;
}

.d-grid {
    display: grid;
}

.relative {
    position: relative;
}

.fn-name-input input {
    width: 50%;
}

.customModalContainer.companies > div {
    position: relative;
}

.customModalContainer.companies .close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
}

button.close-icon > .xmark {
    width: 20px;
    height: 20px;
}

button.close-icon, button.edit-icon {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

button.btn-no-style {
    background: none;
    border: none;
    cursor: pointer;
    outline: inherit;
}

.activeStatusCircle {
    /*background-color: #3C7BFC;*/
    border-radius: 100%;
    width: 10px;
    height: 10px;
}


.peopleFullname {
    text-decoration: none !important;
}


.company-name-wrapper > div.company-name {
    display: flex;
}

.company-name-wrapper > div.company-name > i.fa-pen:hover {
    color: var(--default-blue);
}

.company-name-wrapper > div.company-name > div > input {
    margin-top: 10px;
    margin-bottom: 10px;
}


.customModalContainer.companies .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.modalGrid {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-gap: 0;
    padding: 0 20px 0 20px;
}

.customModalContainer.companies > div {
    width: 50% !important;
}

/* RESPONSIVNES START  ##################################################################### */
@media only screen and (max-width: 470px) {
    .customDropdwn {
        margin-bottom: 20px;
    }

    .fullWithContainer {
        width: 100%;
    }

    div.availability-header {
        margin-top: 20px;
    }

    .profileName {
        font-size: 20px;
        line-height: 20px;
    }

    .peopleFullname {
        font-size: 15px !important;
        line-height: 15px !important;
        margin-right: 10px !important;
    }

    .accessGrid > div.usersGridItem.id-row {
        padding: 0;
    }

    .th-loading {
        display: flex;
    }

    .Toastify__toast {
        margin: 20px 10px !important;
    }
}

@media only screen and (max-width: 576px) {
    .spinner-border {
        margin: 0.5rem;
    }

    .candidatesSearched {
        width: 100%;
    }

    .custom-input {
        min-width: 240px
    }

    .navigation {
        background-color: #FCFCFC;
    }
}

@media only screen and (max-width: 800px) {
    .fn-name-input input {
        width: 100%;
    }

    .spinner-border {
        margin: 0 5px 0 0 !important;
    }

    .activeStatusCircle {
        /*background-color: #3C7BFC;*/
        border-radius: 100%;
        width: 10px;
        height: 10px;
    }

}

@media only screen and (max-width: 1024px) {
    .navigation {
        background-color: #FCFCFC;
    }


}

@media only screen and (max-width: 1260px) {
}

@media only screen and (max-width: 1279px) {
    .customModalContainer.companies > div {
        width: 90% !important;
    }
}

@media only screen and (max-width: 1439px) {
}

@media only screen and (max-width: 1600px) {
}


/* RESPONSIVNES END  ##################################################################### */
.companyManagerAddBtn {
    margin-right: 5.625rem;
}


/*======================
    404 page
=======================*/


.page_404 {
    padding: 10% 0;
    background: #fff;
    font-family: 'Arvo', serif;
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {
    background-image: url('./assets/dribbble_1.gif');
    height: 400px;
    background-position: center;
}

.four_zero_four_bg h1 {
    font-size: 80px;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: var(--default-blue);
    margin: 20px 0;
    text-decoration: none;
    display: inline-block;
}

.contant_box_404 {
    margin-top: -50px;
}

.btn-link {
    background: none !important;
    border: none;
    padding: 0 !important;
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}

.template-click-here {
    margin-bottom: 1rem !important;
}

.gap-2 {
    column-gap: 2rem;
    row-gap: 2rem;
}
